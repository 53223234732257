/* 1366++  */
.b-platform {
    padding: 60px 0;
    position: relative;

    &__title {
        position: relative;
        display: inline-block;
        color: #ed1a64;
        font-size: 48px;
        line-height: 48px;
        font-weight: 400;
        letter-spacing: 14px;
        text-transform: uppercase;
        margin-bottom: 20px;
        padding-right: 50px;

        &:before {
            content: "";
            display: block;
            width: 2px;
            height: 108px;
            background-color: #636363;
            position: absolute;
            right: 0;
            top: -60px;
        }
    }

    &__caption {
        font-size: 16px;
        line-height: 16px;
        font-weight: 400;
        text-transform: uppercase;
        margin-bottom: 40px;

        span {
            color: #ed1a64;
        }
    }

    &__list {
        padding-left: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 -15px  40px;
    }

    &__item {
        list-style: none;
        width: calc(~"100% / 6");
        margin-bottom: 20px;
        padding: 0 15px;
    }

    &__link {
        display: block;
        height: 200px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 300;
        color: #313131;
        text-align: center;
        text-transform: uppercase;
        width: 100%;

        .no-touchevents &:hover,
        .touchevents &:active {

            .b-platform__icon {
                background-image: url(../images/inhtml/platform-bg-active.svg);
                color: #ed1a64;
            }
        }
    }

    &__icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 150px;
        margin: 0 auto 10px;
        background-image: url(../images/inhtml/platform-bg.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        color: #9e9e9e;

        span {
            font-size: 100px;
            line-height: 1;

            &.icon-platform-11 {
                font-size: 75px;
            }
        }
    }

    &__button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 200px;
        height: 62px;
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        background-color: #ed2068;
        border-radius: 6px;
        transition: 0.3s;
        border: 1px solid #ed2068;
        margin-left: auto;

        .icon-download {
            font-size: 26px;
            margin-right: 20px;
        }

        .no-touchevents &:hover,
        .touchevents &:active {
            background-color: #fff;
            color: #ed2068;
        }
    }
}