/* 1366++  */
.b-about {
    background-image: url('../images/inhtml/about-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;

    &__wrapper {
        margin-bottom: 116px;
    }

    &__banner {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
    }

    &__banner-panel {
        position: relative;
        background-color: #ed1a64;
        width: 660px;
        padding: 29px 0 27px 167px;

        &:after,
        &:before {
            content: "";
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            top: 0;
        }

        &:after {
            border-left: 50px solid transparent;
            border-right: 31px solid transparent;
            border-top: 163px solid #ed1a64;
            right: -31px;
        }

        &:before {
            border-left: 31px solid transparent;
            border-right: 50px solid transparent;
            border-bottom: 163px solid #ed1a64;
            left: -31px;
        }
    }

    &__banner-date {
        position: absolute;
        transform: rotate(-90deg);
        transform-origin: left top 0;
        position: absolute;
        left: 39px;
        bottom: -76px;
        font-weight: 700;
        font-size: 39px;
        line-height: 30px;
        letter-spacing: 0.78px;
        color: #222;
        width: 120px;
        text-transform: uppercase;
        z-index: 1;

        span {
            &:first-of-type {
                font-size: 33px;
                line-height: 30px;
                letter-spacing: 0.66px;
            }

            &:last-of-type {
                font-size: 48px;
                line-height: 38px;
                letter-spacing: 0.96px;
            }
        }
    }

    &__banner-text {
        font-weight: 700;
        font-size: 23px;
        line-height: 25px;
        letter-spacing: 0.46px;
        color: #fff;
        text-transform: uppercase;

        span {
            &:first-of-type {
                font-size: 37px;
                line-height: 39px;
                letter-spacing: 0.74px;
            }

            &:last-of-type {
                font-size: 46px;
                line-height: 43px;
                letter-spacing: 0.92px;
            }
        }
    }

    &__image {
        position: absolute;
        top: 40px;
        left: -350px;

        img {
            width: 830px;
            height: 1002px;
        }
    }

    &__panel {
        padding-left: 585px;
        padding-top: 225px;
        position: relative;
    }

    &__list {
        padding-left: 0;
        margin-bottom: 45px;
    }

    &__item {
        list-style: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        padding: 40px 0;

        &:after {
            content: "";
            display: block;
            height: 1px;
            width: 675px;
            background-color: #ed1a64;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }

    &__info {
        color: #313131;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 38px;

        &--padding {
            padding-left: 54px;
        }
    }

    &__icon {
        line-height: 0;
        width: 126px;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__text {
        font-weight: 500;
        font-size: 19px;
        letter-spacing: 0.2px;
        line-height: 37px;

        span {
            font-weight: 900;
            font-size: 37px;
        }
    }

    &__bold {
        font-weight: 700;
        font-size: 104px;
        line-height: 104px;
        letter-spacing: 0.2px;
    }

    &__day {
        flex-direction: column;
        display: flex;
        font-weight: 900;
        font-size: 36px;
        line-height: 40px;
        letter-spacing: 0.7px;
        text-transform: uppercase;
        padding-left: 24px;

        span {
            font-size: 32px;
            letter-spacing: 0.64px;
        }
    }

    &__new {
        display: flex;
        flex-direction: column;
        font-weight: 900;
        font-size: 37px;
        line-height: 37px;
        letter-spacing: 0.74px;
        text-transform: uppercase;
        

        span {
            font-weight: 700;
            font-size: 27px;
            line-height: 30px;
            letter-spacing: 0.54px;

            &:first-of-type {
                padding-top: 8px;
            }
        }
    }


    &__number {
        font-weight: 700;
        font-size: 25px;
        line-height: 25px;
        letter-spacing: 0.5px;
        text-align: center;
        text-transform: uppercase;
    }

    &__sign {
        height: 48px;
        width: 53px;
        position: relative;
        margin-right: 40px;
        margin-left: 17px;
        flex-shrink: 0;

        &:after,
        &:before {
            content: "";
            display: block;
            width: 100%;
            height: 16px;
            position: absolute;
            left: 0;
            background-color: #ed1a64;
        }

        &:after {
            top: 0;
        }

        &:before {
            bottom: 0;
        }
    }

    &__button {
        display: flex;
        flex-direction: row;
        align-items: center;

        &--download {
            display: none;
            margin-right: 40px;
        }

        .icon-tickets,
        .icon-download {
            color: #ed2068;
            font-size: 70px;
            margin-right: 25px;
        }

        .text {
            height: 71px;
            line-height: 71px;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 17px;
            letter-spacing: 0.425px;
            color: #fff;
            background-color: #ed2068;
            width: 245px;
            border-radius: 9px;
            text-align: center;
            transition: 0.3s;
            border: 1px solid #ed2068;
        }

        .no-touchevents &:hover,
        .touchevents &:active {
            .text {
                background-color: #fff;
                color: #ed2068;
            }
        }
    }

    &__link {
        text-align: center;
        padding-bottom: 22px;

        a {
            display: inline-block;
            font-weight: 700;
            font-size: 17px;
            line-height: 20px;
            text-transform: uppercase;
            letter-spacing: 0.34px;
            color: #000;
            text-decoration: underline;
            transition: 0.3s;

            .no-touchevents &:hover,
            .touchevents &:active {
                text-decoration: none;
            }
        }
    }
}