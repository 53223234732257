/* 1366++  */
.b-info {
    padding: 60px 0 35px;
    position: relative;

    &__image {
        line-height: 0;
        position: absolute;
        left: 0;
        top: -50%;

        @media (max-width:1920px) {
            display: none;
        }
    }

    &__group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-weight: 700;
        font-size: 65px;
        line-height: 61px;
        letter-spacing: 6.5px;
        color: #e73a74;
        text-transform: uppercase;
        margin-bottom: 50px;
    }

    &__head {
        width: 430px;

        span {
            color: #343434;
        }
    }

    &__date {
        width: 530px;
        position: relative;
        padding-left: 50px;

        &:before {
            content: "";
            display: block;
            width: 2px;
            height: 158px;
            background-color: #636363;
            position: absolute;
            left: 0;
            top: -60px;
        }
    }

    &__caption {
        font-weight: 300;
        font-size: 21px;
        line-height: 26px;
        color: #5d5d5e;
        margin-bottom: 40px;

        p {
            margin: 0;
        }
    }

    &__list {
        padding-left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        background-image: url('../images/inhtml/info-background.png');
        background-repeat: no-repeat;
        background-position: center top 230px;
    }

    &__item {
        list-style: none;
        text-align: center;
        width: 470px;
        margin-bottom: 35px;

        &:last-child {
            margin: 0 auto;
        }
    }

    &__title {
        font-weight: 800;
        font-size: 25px;
        line-height: 25px;
        letter-spacing: 0.5px;
        color: #e73a74;
        text-transform: uppercase;
    }

    &__text {
        color: #1f1f1f;
        font-weight: 700;
        font-size: 25px;
        line-height: 31px;
        letter-spacing: 0.5px;

        span {
            color: #e73a74;
            text-transform: uppercase;
            font-weight: 800;
        }
    }
}