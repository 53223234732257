/* 1366++  */
.b-popup {
    width: 100%;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    padding: 70px 10px;

    p {
        margin: 0;
        font-weight: 300;
    }
}