/* 1366++  */
.b-media {
    padding-top: 92px;

    &__panel-top {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 123px;
        position: relative;

        @media (max-width:1700px) {
            padding-left: 130px;
        }
    }

    &__title {
        text-transform: uppercase;
        font-size: 53px;
        line-height: 61px;
        letter-spacing: 6.8px;
        color: #e73a74;
        width: 606px;
    }

    &__caption {
        text-transform: uppercase;
        font-weight: 300;
        color: #3e3b39;
        font-size: 19px;
        letter-spacing: 0.38px;
        position: relative;
        padding-left: 60px;
        padding-bottom: 20px;

        &:before {
            content: "";
            display: block;
            width: 2px;
            height: 158px;
            background-color: #636363;
            position: absolute;
            left: 0;
            top: -112px;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
    }

    &__back {
        position: absolute;
        left: -153px;
        top: 257px;
        font-weight: 300;
        font-size: 10px;
        line-height: 10px;
        letter-spacing: 0.2px;
        color: #3e3b39;
        text-transform: uppercase;
        display: inline-block;
        text-align: center;

        .icon-arrow-left {
            display: block;
            line-height: 1;
            color: #ed1a64;
            font-size: 107px;
            opacity: 0.5;
            transition: 0.3s;
        }

        .no-touchevents &:hover,
        .touchevents &:active {
            .icon-arrow-left {
                opacity: 1;
            }
        }

        @media (max-width:1700px) {
            margin-right: 20px;
            top: -25px;
            left: 0;
        }
    }

    &__main {
        flex: 1;
    }

    &__navigation {
        padding-left: 0;
        width: 170px;
        flex-shrink: 0;
    }

    &__navigation-item {
        list-style: none;
        margin-bottom: 50px;

        &:last-child {
            margin-bottom: 0;
        }

        &.active {
            .b-media__image {
                background-color: #eee;
                padding: 30px;
            }

            .b-media__navigation-link {
                .no-touchevents &:hover,
                .touchevents &:active {
                    .b-media__image {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__navigation-link {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 15px;
        line-height: 15px;
        letter-spacing: 0.3px;
        color: #3e3b39;
        text-align: center;
        display: inline-block;

        .no-touchevents &:hover,
        .touchevents &:active {
            .b-media__image {
                opacity: 0.4;
            }
        }
    }

    &__image {
        padding: 0 30px;
        display: block;
        line-height: 0;
        border-radius: 10px;
        margin-bottom: 15px;
        transition: 0.3s;

        img {
            width: 85px;
        }
    }

    &__info {
        font-weight: 300;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 0.44px;
        color: #3e3b39;
        border-bottom: 1px solid #e73a74;
        margin-bottom: 100px;
        padding-bottom: 25px;
    }

    &__tab-list {
        padding-left: 0;
        display: flex;
        flex-direction: row;
        margin: 0 -1px 10px;
    }

    &__tab-item {
        list-style: none;
        padding: 0 1px;
    }

    &__tab-link {
        display: block;
        background-color: #ebebeb;
        font-weight: 700;
        font-size: 53px;
        line-height: 110px;
        color: #e73a74;
        transition: 0.3s;
        height: 110px;
        border-radius: 10px;
        padding: 0 24px;

        .no-touchevents &:hover,
        .touchevents &:active {
            color: #ebebeb;
            background-color: #e73a74;
        }
    }

    &__tab-pane {
        display: none;

        &.active {
            display: block;
        }
    }

    &__list {
        padding-left: 0;
        margin: 0 -2px 15px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__item {
        list-style: none;
        padding: 0 2px;
        margin-bottom: 4px;
        width: 25%;

        &--video {
            width: 50%;
        }
    }

    &__link {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #a1a1a1;
        border-radius: 10px;
        overflow: hidden;
        height: 165px;
        width: 100%;

        img {
            max-height: 100%
        }

        &--video {
            height: auto;
        }
    }

    &__more {
        text-align: right;

        a {
            display: inline-block;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 19px;
            line-height: 21px;
            letter-spacing: 0.38px;
            text-decoration: underline;
            color: #3e3b39;

            .no-touchevents &:hover,
            .touchevents &:active {
                text-decoration: none;
            }
        }
    }
}