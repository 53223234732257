/* 1366++  */
.b-expert {
    position: relative;

    &__wrapper {
        padding: 85px 100px 0 70px;
    }

    &__group {
        display: flex;
        flex-direction: row;
    }

    &__image {
        width: 30%;
    }

    &__description {
        width: 70%;
        padding-left: 80px;
        padding-top: 158px;
        padding-bottom: 140px;
    }

    &__name {
        font-size: 42px;
        line-height: 42px;
        font-weight: 400;
        color: #333;
        margin-bottom: 10px;
    }

    &__content {
        font-size: 14px;
        line-height: 26px;
        max-height: 207px;
        overflow: hidden;

        p {
            margin: 0 0 23px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__title {
        font-size: 74px;
        line-height: 74px;
        font-weight: 400;
        letter-spacing: 25px;
        text-transform: uppercase;
        color: #ed1a64;
        position: absolute;
        top: 42px;
        right: 0;
        width: 70%;
        padding-left: 80px;
    }

    &__panel {
        position: relative;
    }

    &__button-group {
        width: 70%;
        position: absolute;
        bottom: 0;
        right: 0;
        padding-left: 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__arrow-group {
        display: flex;
        flex-direction: row;

        .slick-arrow {
            line-height: 1;
            cursor: pointer;
            opacity: 0.5;
            transition: 0.3s;

            .no-touchevents &:hover,
            .touchevents &:active {
                opacity: 1;
            }

            span {
                color: #ed1a64;
                font-size: 106px;
            }

            &.slick-prev {
                margin-right: 25px;
            }
        }
    }

    &__button {
        background-color: #ed1a64;
        color: #fff;
        height: 107px;
        width: 334px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 10px;
        font-size: 35px;
        transition: 0.3s;
        border: 1px solid #ed2068;

        .no-touchevents &:hover,
        .touchevents &:active {
            background-color: #fff;
            color: #ed2068;
        }
    }

    &__dots {
        padding: 0 50px;
        .slick-list {
            padding: 40px 0 !important;
        }
    }

    &__item {
        filter: grayscale(.9);
        cursor: pointer;
        text-align: center;
        transition: 0.3s;

        &.slick-current,
        .no-touchevents &:hover,
        .touchevents &:active {
            filter: grayscale(0);
            transform: scale(1.2);
        }

        img {
            width: 150px;
            margin: 0 auto;
        }
    }
}