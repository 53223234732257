/* 1366++  */
.b-header {
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;

    .b-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__close {
        display: none;
        background-color: rgba(0,0,0,.8);
        border-radius: 4px 4px 0 0;
        width: 60px;
        height: 45px;
        margin-left: auto;
        padding: 0;
        border: none;
        line-height: 1;
        font-size: 30px;
        color: #fff;
        position: relative;
        z-index: 1;
        padding-top: 10px;

        .no-touchevents &:hover,
        .touchevents &:active {
            padding: 10px 0 0;
        }
    }

    &__menu-button {
        display: none;
        height: 79px;
        width: 100px;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        color: #ed1a64;
        background-color: transparent;
        border: none;
        padding-right: 15px;

        .icon-menu {
            line-height: 1;
            font-size: 30px;
        }
    }

    &__logo {
        color: #ed1a64;
        font-size: 40px;
        line-height: 40px;
        margin-right: 15px;
        transition: 0.3s;

        .no-touchevents &:hover,
        .touchevents &:active {
            opacity: 0.5;
        }

        .icon-logo-chef {
            display: none;
            color: #fff;
            line-height: 1;
            font-size: 70px;
        }
    }

    &__list {
        padding-left: 0;
        display: flex;
        flex-direction: row;
    }

    &__item {
        list-style: none;
        padding: 0 15px;
        position: relative;

        &:first-child {
            &:before {
                display: none;
            }
        }

        &:before {
            content: "";
            display: block;
            width: 2px;
            height: 33px;
            background-color: #666;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
        }
    }

    &__link {
        color: #fff;
        text-transform: uppercase;
        transition: 0.3s;
        line-height: 77px;
        display: block;

        .no-touchevents &:hover,
        .touchevents &:active {
            opacity: 0.5;
        } 
    }

    &__icon {
        display: none;
        color: #ed1a64;
        line-height: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 70px;
        font-size: 40px;
    }


    &__button {
        background-color: #ed1a64;
        color: #fff;
        height: 77px;
        width: 154px;
        text-transform: uppercase;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 15px;
        line-height: 16px;
        padding-left: 11px;
        margin-right: 15px;
        transition: 0.3s;
        border: 1px solid #ed1a64;

        span {
            font-size: 46px;
            margin-right: 10px;
        }

        
        .no-touchevents &:hover,
        .touchevents &:active {
            background-color: #fff;
            color: #ed1a64;
        }
    }

    &__group {
        position: relative;
    }

    &__phone {
        color: #fff; 
        font-weight: 600;
        margin-left: 15px;
        font-size: 32px;
        line-height: 1;
        margin-left: auto;
        transition: 0.3s;

        .no-touchevents &:hover,
        .touchevents &:active {
            opacity: 0.5;
        }
    }
}