/* 1366++  */
.b-modal {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 6px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 100%;
    max-width: 685px;

    &__content {
        position: relative;
        white-space: normal;
    }

    &__close {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 55px;
        height: 55px;
        z-index: 10;
        color: red;
        font-size: 35px;
    }
}

.modal {
    white-space: nowrap;
    padding: 20px 0;
    text-align: center;

    &:before {
        content:"";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
        width: 0;
    }
}

.modal-backdrop {
    position: fixed;
    z-index: 1000;
    background-color: #000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.in {
        opacity: .8;
    }
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  display: block;
  visibility: hidden;
  overflow-y: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  -webkit-overflow-scrolling: touch;
  outline: 0;

  &.in {
    visibility: visible;
  }
}
