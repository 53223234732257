/* 1366++  */
.b-program {
    margin-bottom: 100px;
    padding-top: 100px;

    &__head {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 48px;
        letter-spacing: 14.4px;
        color: #ed1a64;
        text-transform: uppercase;
        margin-bottom: 80px;
    }

    &__caption {
        font-weight: 800;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: 2.2px;
        color: #313131;
        position: relative;
        padding-left: 89px;
        padding-top: 26px;
        padding-bottom: 42px;

        &:before {
            content: "";
            display: block;
            height: 100%;
            width: 2px;
            background-color: #636363;
            position: absolute;
            left: 44px;
            top: 0;
        }

        span {
            color: #ed1a64;
            display: block;
        }
    }

    &__list {
        padding-left: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &__item {
        list-style: none;
        width: calc(~"100% / 3");
        border-top: 1px solid #e5e5e5;
        border-right: 1px solid #e5e5e5;
        height: 400px;
        padding: 46px 20px 0;

        &:nth-child(3n) {
            border-right: none;
        }

        &:nth-child(-n+3) {
            border-top: none;
        }

        &--button {
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }

    &__image {
        line-height: 0;
        text-align: center;

        img {
            height: 87px;
            width: auto;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 26px;
        line-height: 40px;
        color: #ed1a64;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 9px;

        span {
            color: #4f4f51;
        }
    }

    &__text {
        font-size: 18px;
        line-height: 24px;
        color: #515158;
        text-align: center;
        margin-bottom: 25px;
    }

    &__button {
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.4px;
        color: #ed1a64;
        border: 1px solid #ed1a64;
        text-align: center;
        width: 146px;
        height: 40px;
        line-height: 38px;
        margin: 0 auto;
        display: block;
        text-transform: uppercase;
        border-radius: 8px;
        transition: 0.3s;

        .no-touchevents &:hover,
        .touchevents &:active {
            color: #fff;
            background-color: #ed1a64;
        }
    }

    &__link {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 88px;
        background-color: #ed1a64;
        color: #fff;
        font-size: 24px;
        line-height: 26px;
        text-transform: uppercase;
        width: 348px;
        flex-shrink: 0;
        border-radius: 8px;

        .icon-list {
            font-size: 45px;
            margin-right: 15px;
            position: relative;
            top: 4px;
        }
    }

    &__close {
        display: none;
        width: 18px;
        height: 18px;
        background-color: #a1a1a1;
        border-radius: 100%;
        position: absolute;
        right: 8px;
        top: 7px;

        &:after,
        &:before {
            content: "";
            display: block;
            width: 10px;
            height: 2px;
            background-color: #fff;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
}