/* 1366++  */
.b-logo {
    position: absolute;
    top: 11px;
    left: 60px;
    color: #5d5d5e;

    &--pink {
        color: #e73a74;
    }

    &--dark {
        color: #333;
    }

    &--light {
        color: #918f8f;
    }

    &--top {
        top: 92px;
    }

    span {
        font-size: 155px;
        line-height: 1;
    }

    @media (max-width:1700px) {
        display: none;
    }
}