/* 1366++  */
.b-buy-ticket {
    background-image: url('../images/inhtml/buy-ticket-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 80px 0 50px;
    position: relative;

    &__group {
        margin-bottom: 95px;
    }

    &__head {
        font-weight: 700;
        font-size: 57px;
        line-height: 47px;
        color: #5d5d5e;
        text-transform: uppercase;

        span {
            color: #ed1a64;
        }
    }

    &__caption {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        background-color: #ed1a64;
        padding: 0 10px 0 19px;
        border-radius: 0 0 10px 10px;
        position: relative;
        display: inline-block;
        margin-left: 99px;

        &:before {
            content: "";
            display: block;
            width: calc(~"100% + 99px");
            height: 1px;
            position: absolute;
            left: -99px;
            top: 0;
            background-color: #ed1a64;
        }
    }

    &__label {
        font-weight: 700;
        font-size: 17px;
        line-height: 17px;
        letter-spacing: 0.34px;
        color: #ed1a64;
        text-transform: uppercase;
        padding-left: 28px;
        margin-bottom: 12px;
    }

    &__list {
        padding-left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    &__item {
        list-style: none;
        position: relative;
        width: 25%;

        &:before {
            content: "";
            display: block;
            width: 3px;
            height: 51px;
            position: absolute;
            left: 0;
            top: 19px;
            background-color: #ed1a64;

        }


        &:first-child {
            &:before {
                display: none;
            }
        }

        &.active {
            .b-buy-ticket__link span {
                span {
                    color: #ed1a64;
                }
            }
        }
    }

    &__link {
        text-align: center;
        display: block;
        position: relative;
        transition: 0.3s;

        .no-touchevents &:hover,
        .touchevents &:active {
            .b-buy-ticket__link-group {
                span {
                    color: #ed1a64;
                }
            }
        }

        .icon-tickets {
            display: none;
            position: absolute;
            bottom: 6px;
            left: 6px;
            color: #ed1a64;
            font-size: 25px;
            line-height: 1;
            transition: 0.3s;
        }

        .text {
            display: none;
        }
    }

    &__link-group {
        font-weight: 500;
        font-size: 22px;
        line-height: 15px;
        letter-spacing: -1.3px;
        color: #ed1a64;
        display: inline-block;
        text-align: right;
        transition: 0.3s;

         span {
            font-weight: 900;
            font-size: 80px;
            line-height: 80px;
            letter-spacing: -4.8px;
            color: #7c7c7d;
            display: block;
            transition: 0.3s;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 104px;
        line-height: 80px;
        color: #ed1a64;
        border-bottom: 3px solid #ed1a64;
        text-transform: uppercase;
        text-align: right;
        margin-bottom: 35px;
    }

    &__info {
        font-weight: 700;
        font-size: 26px;
        line-height: 26px;
        letter-spacing: 0.5px;
        color: #ed1a64;
        text-transform: uppercase;
        margin-bottom: 25px;
    }

    &__tab-pane {
        display: none;

        &.active {
            display: block;
        }

        ul {
            padding-left: 0;
        }

        li {
            list-style: none;
            position: relative;
            padding-left: 35px;
            color: #5d5d5e;
            font-size: 21px;
            line-height: 25px;
            letter-spacing: 0.42px;
            margin-bottom: 25px;

            &:before {
                content: "";
                display: block;
                width: 9px;
                height: 9px;
                border-radius: 100%;
                background-color: #ed1a64;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto;
            }
        }
    }

    &__button {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 352px;

        .icon-tickets {
            color: #ed2068;
            font-size: 70px;
            margin-right: 25px;
        }

        .text {
            height: 71px;
            line-height: 71px;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 17px;
            letter-spacing: 0.425px;
            color: #fff;
            background-color: #ed2068;
            width: 245px;
            border-radius: 9px;
            text-align: center;
            border: 1px solid #ed2068;
            transition: 0.3s;
        }

        .no-touchevents &:hover,
        .touchevents &:active {
            .text {
                background-color: #fff;
                color: #ed2068;
            }
        }
    }
}