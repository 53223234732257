/* 1024 + */
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    font-family: 'Geometria';
    min-width: 320px;
}

* {
    outline: none;
}