/* 1366++  */
.b-how-pass {
    padding-top: 120px;
    position: relative;

    &__panel-top {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 70px;
    }

    &__title {
        font-size: 50px;
        line-height: 40px;
        font-weight: 200;
        letter-spacing: 7px;
        text-transform: uppercase;
    }

    &__label {
        font-size: 30px;
        text-transform: uppercase;
        letter-spacing: 3px;
        line-height: 30px;
        margin-bottom: 55px;
    }

    &__caption {
        text-transform: uppercase;
        font-size: 15px;
        line-height: 19px;
        letter-spacing: 0.8px;
        padding-left: 110px;
        position: relative;

        &:before {
            content: "";
            display: block;
            width: 4px;
            height: 208px;
            background-color: #ed1a64;
            position: absolute;
            left: 80px;
            top: -150px;
        }
    }

    &__info {
        padding-left: 110px;
    }

    &__group {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;

        &--no-margin {
            margin-bottom: 0;
        }
    }

    &__panel,
    &__info,
    &__tab-content,
    &__image,
    &__caption,
    &__title {
        width: 50%;
    }

    &__list {
        padding-left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__item {
        list-style: none;
    }

    &__link {
        display: inline-block;
        text-align: center;
        color: #9f9f9f;
        transition: 0.3s;
        text-transform: uppercase;
        font-size: 15px;

        .icon-car {
            font-size: 40px;
            display: block;
            margin-bottom: 15px;
        }

        .icon-bus {
            font-size: 48px;
        }

        .text {
            display: block;
            padding-top: 7px;
        }

        .no-touchevents &:hover,
        .touchevents &:active {
            color: #ed1a64;

            .text {
                text-decoration: underline;
            }
        }
    }

    &__info {
        line-height: 15px;
    }

    &__tab-pane {
        display: none;

        &.active {
            display: block;
        }
    }

    &__map {
        padding-right: 3px;
        border-radius: 17px;
        overflow: hidden;
        height: 545px;

        &--height {
            height: 353px;
        }
    }

    &__map-info {
        div {
            margin-bottom: 15px;
        }

        p {
            margin: 0;
        }
    }

    &__image {
        padding-left: 3px;
        line-height: 0;
        border-radius: 17px;
        overflow: hidden;
        height: 545px;

        img {
            height: 100%;
            max-width: none;
            width: auto;
        }
    }
}