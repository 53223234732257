/* 1366++  */
.b-footer {
    background-color: #000;
    padding-bottom: 24px;
    margin-top: 80px;
    padding-top: 25px;

    .b-container {
        padding: 0 55px;
    }

    &__phone {
        color: #fff;
        font-size: 50px;
        line-height: 50px;
        font-weight: 700;
        transition: 0.3s;

        .no-touchevents &:hover,
        .touchevents &:active {
            opacity: 0.5;
        }
    }

    &__panel-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #8c8c8c;
        margin-bottom: 15px;
        padding-bottom: 15px;
    }

    &__group {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__logo {
        margin-right: 45px;
        width: 90px;
        transition: 0.3s;

        &:last-child {
            margin-right: 0;
        }

        &--width {
            width: 123px;
        }

        .no-touchevents &:hover,
        .touchevents &:active {
            opacity: 0.5;
        }
    }

    &__list {
        padding-left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    &__item {
        list-style: none;
    }

    &__link {
        color: #848484;
        text-transform: uppercase;
        font-size: 16px;
        transition: 0.3s;

        .no-touchevents &:hover,
        .touchevents &:active {
            opacity: 0.5;
        }
    }

    &__panel-bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__copyright {
        font-size: 15px;
        line-height: 15px;
        font-weight: 400;
        color: #fff;
        text-transform: uppercase;
    }

    &__social-list {
        display: flex;
        flex-direction: row;
        padding-left: 0;
    }

    &__social-item {
        list-style: none;
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
        }
    }

    &__social-link {
        display: block;
        width: 46px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        background-color: #949494;
        border-radius: 100%;
        transition: 0.3s;

        span {
            color: #000;
            font-size: 27px;
        }

        .icon-vk {
            font-size: 17px;
        }

        .icon-telegram {
            position: relative;
            left: -2px;
        }

        &--fb {
            .no-touchevents &:hover,
            .touchevents &:active {
                background-color: #5f81c4;
            }
        }

        &--vk {
            .no-touchevents &:hover,
            .touchevents &:active {
                background-color: #4b75a2;
            }
        }

        &--inst {
            .no-touchevents &:hover,
            .touchevents &:active {
                background-color: #946246;
            }
        }

        &--telegram {
            .no-touchevents &:hover,
            .touchevents &:active {
                background-color: #29a8f7;
            }
        }

        &--whatsup {
            .no-touchevents &:hover,
            .touchevents &:active {
                background-color: #22b080;
            }
        }
    }
}