/* 1366++  */
.b-subscribe {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 50;
    text-align: right;

    &__link {
        display: inline-block;
        position: relative;
        background-color: #ed1a64;
        padding: 21px 0 23px 60px;
        border-radius: 0 95px 0 0;
        text-align: left;
    }

    &__icon {
        line-height: 0;
        position: absolute;
        bottom: -6px;
        left: -60px;
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        padding: 0;
        border: 1px solid #ed1a64;
        display: block;
        border-radius: 100%;
        width: 28px;
        height: 28px;

        &:after,
        &:before {
            content: "";
            display: block;
            width: 16px;
            height: 4px;
            background-color: #ed1a64;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
        }

        &:after {
            transform: rotate(45deg);
        }

        &:before {
            transform: rotate(-45deg);
        }
    }

    &__text {
        font-size: 16px;
        line-height: 18px;
        font-weight: 700;
        color: #fefefe;
        text-transform: uppercase;
        display: block;
        width: 460px;

        span {
            font-weight: 900;
        }
    }
}